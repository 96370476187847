<template xmlns="http://www.w3.org/1999/html">
  <TopNav></TopNav>
  <h1>Adet Günü Hesaplama</h1>
  <fieldset>
    <legend>Adet Günü Hesaplama Aracı</legend>
    <label for="regltarihi">Son Adet Tarihi:</label> <input type="date" v-model="regl" id="regltarihi">
    <br>
    <label for="gun">Adet Döngüsü:</label> <input type="number" v-model.number="gunValue" id="gun" min="0"> gün.
    <br><br>
    Sonraki Adet Tarihi: <span class="blue">{{ sonrakitarih() }}</span>
  </fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'
import moment from 'moment'

export default {
  name: "AdetGunu",
  data() {
    return {
      regl: new Date(),
      gunValue: 28
    }
  },
  components: {
    TopNav
  },
  methods: {
    sonrakitarih: function () {
      moment.locale('tr')
      return moment(this.regl).add(this.gunValue, 'days').format("LL");
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

label {
    display: inline-block;
    min-width: 145px;
}

footer section a {
  margin: 20px;
}

.blue {
    color: blue;
}
</style>
